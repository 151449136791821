import React from "react"
import { Layout, Title, Documents } from "../components/index"
import {Helmet} from 'react-helmet';
import Favicon from '../img/favicon3.png';

export default () => (
  <div className="dokumenti">
    <Layout>
        <Helmet>
                <title>Dokumenti - Umjetnička organizacija Kreativna Kuća</title>
                <meta name='title' content='Dokumenti - Umjetnička organizacija Kreativna Kuća' />
                <meta name='description' content='KREATIVNA KUĆA je umjetnička organizacija osnovana 2018. godine koja se bavi objavljivanjem i distribucijom kvalitetnih književnih djela, izradom autorskih animiranih filmova i organiziranjem i provedbom kreativnih radionica i manifestacija vezanih za književnost i animirani film. Naša je misija povezivanje zbilje i mašte u originalne umjetničke izričaje i širenje prostora stvaralačke slobode. Osnivači i glavni provoditelji programa Umjetničke organizacije KREATIVNA KUĆA su višestruko nagrađivana književnica i scenaristica Nena Lončar i likovni umjetnik Marijan Lončar.' />
                <link rel='shortcut icon' type='image/x-icon' href={Favicon} />
                <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0' />
        </Helmet>
      <Title title='Dokumenti' subtitle='Dokumenti umjetničke organizacije Kreativna Kuća'></Title>
      <Documents />
    </Layout>
  </div>
)